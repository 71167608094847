import {ApolloClient, HttpLink, InMemoryCache} from "@apollo/client";
import fetch from 'cross-fetch';
import environment from "../environments/environment";

const client = new ApolloClient({
    uri: `${environment.apiUrl}/graphql`,
    link: new HttpLink({ uri: `${environment.apiUrl}/graphql`, fetch }),
    cache: new InMemoryCache(),
});

export default client;