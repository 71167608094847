import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import './ColoredScrollbars.css'

export default class ColoredScrollbars extends Component {

  constructor(props, ...rest) {
        super(props, ...rest);
        this.state = { top: 0 };
        this.handleUpdate = this.handleUpdate.bind(this);
        this.renderView = this.renderView.bind(this);
        this.renderThumb = this.renderThumb.bind(this);
        this.renderTrack= this.renderTrack.bind(this);
        this.scrollRef = React.createRef();
    }

    handleUpdate(values) {
        const { top } = values;
        this.setState({ top });
    }

    renderView({ style, ...props }) {
        return (
            <div
                className="box"
                style={{
                    ...style,
                    'overflow': 'hidden auto',
                }}
                {...props}/>
        );
    }

    renderThumb({ style, ...props }) {
        const thumbStyle = {
            backgroundColor: 'white',
            width: 16
        };
        return (
            <div
                style={{ ...style, ...thumbStyle }}
                {...props}/>
        );
    }

    renderTrack({ style, ...props }) {
        const trackStyle = {
            backgroundColor: this.isScroll()
              ? 'rgba(255, 190, 210, 0.05)'
              : 'transparent',
            height: '100vh',
            width: 16,
            right: 0,
            zIndex: 999,
            display: 'block'
        };
        return (
            <div
                style={{ ...style, ...trackStyle }}
                {...props}/>
        );
    }

    isScroll() {
      const scrollbars = this.scrollRef.current;

      if (scrollbars) {
        return scrollbars.getScrollHeight() > scrollbars.getClientHeight()
      }
      return false;
    }

    render() {
        return (
            <Scrollbars
                ref={this.scrollRef}
                renderView={this.renderView}
                renderThumbVertical={this.renderThumb}
                renderTrackVertical={this.renderTrack}
                onUpdate={this.handleUpdate}
                {...this.props}/>
        );
    }
}
