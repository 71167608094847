/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "./layout.css"
import Header from "./header/Header"
import Footer from "./footer/Footer";
import {ApolloProvider} from "@apollo/client";
import client from "../configuration/ApolloClient";
import ColoredScrollbars from "./ColoredScrollbars/ColoredScrollbars";

const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

    return (
        <>
            <ColoredScrollbars
                style={{ height: '100vh' }}>
            <ApolloProvider client={client}>
                <Header siteTitle={data.site.siteMetadata.title} />
                <main>{children}</main>
                <Footer />
            </ApolloProvider>
            </ColoredScrollbars>
        </>
    )
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout
